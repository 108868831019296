<template>
  <div class="row" v-if="loading">
    Loading...
  </div>
  <div class="col-4 text-center mb-4" v-else>
    <div class="row m-0">
      <div class="col-4 p-0 clickable" v-for="(c, i) of itineraries" :key="'conf_'+i" @click="getItineraryPdf(c.id)">
        <div class="col-12 p-0">
          <img src="../assets/images/icon_doc.png" class="doc_icn">
        </div>
        <div class="col-12 p-0 mt-2">
          {{formatDate(c.dateIn)}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.getItineraries().then(() => {
      this.loading = false
    })
  },
  computed: {
    ...mapGetters([
      'itineraries'
    ])
  },
  methods: {
    ...mapActions([
      'getItineraries',
      'getItineraryPdf'
    ]),
    formatDate (d) {
      return moment(d).format('MMM YYYY')
    }
  }
}
</script>
<style scoped>
</style>
